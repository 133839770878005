import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';

export const HeadersInterceptor: HttpInterceptorFn = (
	request: HttpRequest<unknown>,
	next: HttpHandlerFn,
) => {
	request = request.clone({
		headers: request.headers.set('Referrer-Policy', 'origin-when-cross-origin'),
	});

	return next(request);
};
