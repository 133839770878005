import { Injectable } from '@angular/core';

declare let window: any;

@Injectable({
	providedIn: 'root',
})
export class AppOrientationService {
	lockPortrait(): void {
		window.screen.orientation.lock('portrait');
	}
}
