import { Directive, ElementRef, Renderer2, inject, effect } from '@angular/core';
import { UserService } from '@shared/services/user.service';

@Directive({
	standalone: true,
	selector: '[isLoggedIn]',
})
export class IsLoggedInDirective {
	private el: ElementRef = inject(ElementRef);
	private renderer: Renderer2 = inject(Renderer2);

	isLoggedIn = inject(UserService).isLoggedIn;

	constructor() {
		effect(() => {
			if (this.isLoggedIn()) this.renderer.addClass(this.el.nativeElement, 'is-logged-in');
			else this.renderer.removeClass(this.el.nativeElement, 'is-logged-in');
		});
	}
}
