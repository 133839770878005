import { Injectable, signal, WritableSignal } from '@angular/core';
import { SafeArea } from 'capacitor-plugin-safe-area';

@Injectable({
	providedIn: 'root',
})
export class AppSafeAreaInsetService {
	private _top: WritableSignal<number> = signal(0);
	private _bottom: WritableSignal<number> = signal(0);
	private _right: WritableSignal<number> = signal(0);
	private _left: WritableSignal<number> = signal(0);

	top = this._top.asReadonly();
	bottom = this._bottom.asReadonly();
	right = this._right.asReadonly();
	left = this._left.asReadonly();

	fnInit() {
		// SafeArea.addListener('safeAreaChanged', data => {
		//
		// });

		SafeArea.setImmersiveNavigationBar();

		SafeArea.getSafeAreaInsets().then(({ insets }) => {
			for (const [key, value] of Object.entries(insets)) {
				document.documentElement.style.setProperty(`--safe-area-${key}`, `${value}px`);
				if (key == 'top') this._top.set(value);
				if (key == 'bottom') this._bottom.set(value);
				if (key == 'right') this._right.set(value);
				if (key == 'left') this._left.set(value);
			}
		});
	}
}
