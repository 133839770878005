import {
    HttpRequest,
    HttpHandlerFn,
    HttpErrorResponse,
    HttpHandler,
    HttpInterceptor,
    HttpEvent, HttpInterceptorFn
} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {UserService} from '@shared/services/user.service';
import {catchError, Observable, of, throwError} from "rxjs";
import {Router} from "@angular/router";
import {AppRouteService} from "@shared/services/app-route.service";

export const AuthInterceptor: HttpInterceptorFn = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn,
) => {
    const url: string = req.url;
    const userService = inject(UserService);
    const appRouteService = inject(AppRouteService);
    const router = inject(Router);

    //console.log("URL",url)
    //if (!url.startsWith(environment.apiEndpoint)) return next(req);

    if (url.indexOf(environment.apiEndpoint + '/Users/login') > -1 ||
        url.indexOf(environment.apiEndpoint + '/Users/forgotPassword') > -1 ||
        url.indexOf(environment.apiEndpoint + '/Users/register') > -1 ||
        url.indexOf(environment.apiEndpoint + '/Users/confirmEmail') > -1 ||
        url.indexOf(environment.apiEndpoint + '/Users/resendConfirmationEmail') > -1) {
        return next(req);
    }

    let request = req.clone({
        setHeaders: {
            Authorization: '' + userService.accessToken(),
        },
    });

    // catch the error, make specific functions for catching specific errors and you can chain through them with more catch operators
    return next(request).pipe(catchError(err=> {
        console.log(err.status, "????")
        if (err.status === 401 || err.status === 403) {
            userService.logoutUser();
            //navigate /delete cookies or whatever
            router.navigate([appRouteService.routeAccess.login.routeConfig.path]).then();
            // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
            return of(err.message); // or EMPTY may be appropriate here
        }
        return throwError(err);
    }));
};
