import { inject, Injectable, NgZone, signal } from '@angular/core';
import { Network } from '@capacitor/network';

@Injectable({
	providedIn: 'root',
})
export class AppNetworkService {
	private ngZone: NgZone = inject(NgZone);

	hasConnection = signal(true);

	fnInit() {
		Network.addListener('networkStatusChange', status => {
			this.ngZone.run(() => {
				this.hasConnection.set(status.connected);
			});
		});
	}
}
