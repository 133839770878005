@if (!hasConnection()) {
    <div class="connection-failed">
        <mat-icon color="white" class="item-margin-right">wifi_off</mat-icon>
        {{ translations.global.no_internet_connection | translate }}
    </div>
}

@if (loading()) {
    <div class="center-spinner">
        <mat-spinner class="primary" diameter="30" />
    </div>
} @else {
    <div class="flex flex-col flex-grow width-100 overflow-hidden">
        <div class="flex flex-grow w-full overflow-hidden" @fade>
            <router-outlet />
        </div>
    </div>
}