import { Injectable } from '@angular/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';

@Injectable({
	providedIn: 'root',
})
export class AppStatusBarService {
	fnInit() {
		this.setOverlaysWebView();
		this.setStatusBarStyleDark();
	}

	setOverlaysWebView = async () => {
		// Display content under transparent status bar (Android only)
		if (Capacitor.getPlatform() == 'android') StatusBar.setOverlaysWebView({ overlay: true });
	};

	setStatusBarStyleDark = async () => {
		await StatusBar.setStyle({ style: Style.Dark });
	};

	async setStatusBarStyleLight() {
		await StatusBar.setStyle({ style: Style.Light });
	}

	async hideStatusBar() {
		await StatusBar.hide();
	}

	async showStatusBar() {
		await StatusBar.show();
	}
}
