import { Routes } from '@angular/router';
import { Injector } from '@angular/core';
import { TRouteAccess } from '@shared/interfaces/route.interface';
import {AppRouteService, ETruckDetailRoutes} from '@shared/services/app-route.service';
import {ReturnComponent} from "@pages/return/return.component";

const injector = Injector.create({
	providers: [{ provide: AppRouteService, useClass: AppRouteService }],
});
const appRouteService = injector.get(AppRouteService);
const routeAccess: TRouteAccess = appRouteService.routeAccess;

export const routes: Routes = [
	{ path: '', redirectTo: appRouteService.defaultRoute, pathMatch: 'full' },
	{
		path: routeAccess.trucks.routeConfig.path,
		children: [
			{
				path: '',
				loadComponent: () =>
					import('@pages/trucks/trucks.component').then(mod => mod.TrucksComponent),
			},
			{
				path: ':truckId/' + ETruckDetailRoutes.LOCATION,
				loadComponent: () =>
					import('@pages/location/location.component').then(mod => mod.LocationComponent),
			},
			{
				path: ':truckId/' + ETruckDetailRoutes.MAIL,
				loadComponent: () =>
					import('@pages/mail/mail.component').then(mod => mod.MailComponent),
			},
			{
				path: ':truckId/' + ETruckDetailRoutes.RETURN,
				loadComponent: () =>
					import('@pages/return/return.component').then(mod => mod.ReturnComponent),
			},
		],
		canActivate: routeAccess.trucks.canActivate,
		data: {},
	},
	{
		path: routeAccess.scan.routeConfig.path,
		loadComponent: () => import('@pages/scan/scan.component').then(mod => mod.ScanComponent),
		canActivate: routeAccess.scan.canActivate,
		data: {},
	},
	{
		path: routeAccess.login.routeConfig.path,
		loadComponent: () => import('@pages/auth/login/login.component').then(mod => mod.LoginComponent),
		canActivate: routeAccess.login.canActivate,
		data: {},
	},

	{ path: '**', redirectTo: appRouteService.defaultRoute },
];
